.coupon-codes {
    .bg-blue-banner {
        background-color: #323232;
        height: 250px;
        width: 100%;
        position: absolute;
        z-index: -1;
    }

    .page-head {
        padding-top: 40px;
        padding-bottom: 40px;

        h1 {
            font-size: 30px;
            font-weight: 700;
            color: #FFF;
        }

        p {
            margin-bottom: 0px;
            color: #253d8d;
            font-weight: 600;
        }

        .import-btn {
            display: flex;
            align-items: center;
            padding: 8px 25px;
            background-color: #FFF;
            font-size: 14px;
            color: #323232;
            font-weight: 600;
            border: 0px solid #DBDFEA;

            .btn-icon {
                margin-right: 10px;
            }
        }

        .add-btn {
            background-color: #FFF;
            margin-left: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 0px solid transparent;
        }
    }

    .coupon-codes-list {
        .coupon-codes-listbody {
            .activity-card {
                border: 1px solid #DBDFEA !important;

                .activity-cardbody {
                    h5 {
                        margin-bottom: 5px;
                        font-size: 16px;
                        font-weight: 600;
                    }

                    p {
                        margin-bottom: 0px;
                        font-size: 14px;
                        color: grey;
                        line-height: 20px;
                    }

                    .active-badge {
                        background-color: rgba($color: #28C76F, $alpha: 0.2) !important;
                        color: #28C76F !important;
                        padding: 5px 15px;
                    }

                    .expired-badge {
                        background-color: rgba($color: #EA5455, $alpha: 0.2) !important;
                        color: #EA5455 !important;
                        padding: 5px 15px;
                    }
                }

                .activity-cardfooter {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background-color: #FFF;
                    border-top: 1px solid #DBDFEA;

                    .date-range {
                        span {
                            font-size: 12px;
                            color: grey;
                            font-weight: 500;
                        }

                        p {
                            font-size: 14px;
                            margin-bottom: 0px;
                            font-weight: 600;
                            color: grey;
                        }
                    }

                    .card-action {
                        .dropdown-toggle {
                            background-color: #FFF;
                            border-radius: 50%;
                            padding: 6px 10px;
                            border-color: #FFF;
                        }

                        .dropdown-toggle:hover {
                            background-color: rgba($color: #323232, $alpha: 0.1);
                        }

                        .dropdown-toggle::after {
                            display: none;
                        }

                        .card-action-menu {
                            .menu-item {
                                font-size: 13px;
                                color: #323232;
                                padding-top: 10px;
                                padding-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}